import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import * as Yup from 'yup';
import { GetBlogs, GetCollections } from '../common/api';

const Contact = () => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);

  const schema = Yup.object().shape({
    name: Yup.string().required('required'),
    email: Yup.string().email().required('required'),
    subject: Yup.string().required('required'),
    message: Yup.string().required('required'),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await GetBlogs();
      response && setBlogs(response);
    }
    const fetchCollections = async () => {
      const response = await GetCollections();
      response && setCollections(response);
      console.log(response);
    }

    fetchBlogs();
    fetchCollections();
  }, [])

  const onSubmit = (data: any) => {
    console.log('onSubmit =====>', data);
  }

  return (
    <Box maxWidth={1300} py={2.5} mx='auto' boxSizing='border-box'>
      <Breadcrumbs sx={{ my: 2.5 }}>
        <Link underline='hover' color='inherit' href='/'>Home</Link>
        <Link underline='hover' color='inherit'>Contact</Link>
      </Breadcrumbs>
      <Grid container>
        <Grid item xs={12} lg={8} p={1}>
          <Typography variant='h5' mb={2.5} fontWeight={600}>Get In Touch</Typography>
          <Typography mb={2.5}>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Box bgcolor='#fff' borderRadius={2} boxShadow={3} p={2.5}>
                <Typography variant='h6' mb={2.5} fontWeight={600}>Address</Typography>
                <Typography>1328 Oak Ridge Drive, Saint Louis, Missouri - 63102</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box bgcolor='#fff' borderRadius={2} boxShadow={3} p={2.5}>
                <Typography variant='h6' mb={2.5} fontWeight={600}>Contact</Typography>
                <Typography>313-332-8662</Typography>
                <Typography>info@jstemplate.net</Typography>
              </Box>
            </Grid>
          </Grid>
          <Box bgcolor='rgb(241 245 249)' borderRadius={2} boxShadow={3} mt={5} p={2.5}>
            <Typography variant='h5' px={2} py={1} fontWeight={600}>Leave a Message</Typography>
            <Grid container>
              <Grid item xs={12} lg={6} px={2} py={1}>
                <TextField { ...register('name')} fullWidth placeholder='Your Name' />
                <Typography fontSize={12} color='#f00'>{errors.name?.message}</Typography>
              </Grid>
              <Grid item xs={12} lg={6} px={2} py={1}>
                <TextField { ...register('email')} fullWidth placeholder='Your Email' />
                <Typography fontSize={12} color='#f00'>{errors.email?.message}</Typography>
              </Grid>
              <Grid item xs={12} lg={12} px={2} py={1}>
                <TextField { ...register('subject')} fullWidth placeholder='Subject' />
                <Typography fontSize={12} color='#f00'>{errors.subject?.message}</Typography>
              </Grid>
              <Grid item xs={12} lg={12} px={2} py={1}>
                <TextField { ...register('message')} fullWidth multiline={true} rows={5} placeholder='Write your message...' />
                <Typography fontSize={12} color='#f00'>{errors.message?.message}</Typography>
              </Grid>
            </Grid>
            <Typography px={2} py={1} fontSize={14}>We care about your data in our Privacy Policy</Typography>
            <Button variant='contained' sx={{ m: 2 }} onClick={handleSubmit(onSubmit)}>Send Message</Button>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} p={1}>
          <Box bgcolor='#fff' borderRadius={2} boxShadow={3} p={2.5}>
            <Typography variant='h5' fontWeight={600}>Latest Post</Typography>
            {blogs.slice(0, 5).map((item, index) => (
              <Box key={index} mt={2.5} display='flex' alignItems='center' onClick={() => navigate(`/post/${item._id}`)}>
                <img src={item.image} alt='' style={{ width: '40%' }} />
                <Box ml={1.5}>
                  <Typography>{item.title}</Typography>
                  <Typography fontSize={14}>{format(new Date(item.createdAt), 'MMM dd, yyyy')}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box bgcolor='#fff' borderRadius={2} boxShadow={3} mt={2.5} p={2.5}>
            <Typography variant='h5' fontWeight={600}>Collections</Typography>
            <List>
              {collections.slice(0, 8).map((item, index) => (
                <Box key={index}>
                  <ListItem>
                    <ListItemText>{item.title}</ListItemText>
                    <Badge badgeContent={item.volume} color='primary' />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Contact;
