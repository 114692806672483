import { RouteObject, useRoutes } from 'react-router-dom';
import Layout from '../layout/layout';
import Home from '../page/home';
import Post from '../page/post';
import Contact from '../page/contact';

const AppRoutes = () => {
  const routes: RouteObject[] = [
    {
      path: '',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Home />
        },
        {
          path: '/post/:id',
          element: <Post />
        },
        {
          path: '/contact',
          element: <Contact />
        }
      ]
    }
  ]

  return useRoutes(routes);
}

export default AppRoutes;
