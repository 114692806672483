import { Box, styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

const Main = styled('main')(() => ({
  minHeight: 'calc(100vh - 220px)',
}))

const Layout = () => {
  return (
    <Box>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Box>
  )
}

export default Layout;
