import { Box, Button, Typography } from '@mui/material';
import { Footers } from '../common/const';
import Logo from '../asset/logo.png';

const Footer = () => {
  return (
    <Box height={120} pt={2} pb={3} boxSizing='border-box'>
      <Box maxWidth={1200} height={80} mx='auto' display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <img src={Logo} alt='' style={{ width: 80, cursor: 'pointer' }} onClick={() => window.location.href = 'https://klik.cool' } />
          <Box display='flex' flexDirection='column'>
            <Typography fontSize={20} fontWeight={600}>KLIK</Typography>
            <Typography>@{new Date().getFullYear()}, All Rights Reserved</Typography>
          </Box>
        </Box>
        <Box display='flex'>
          {Footers.map((item, index) => (
            <Button key={index} variant='text' href={item.link}>{item.label}</Button>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Footer;
