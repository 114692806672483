import React, {useEffect, useState} from 'react';
import { Box, Popper, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { Headers } from '../common/const';
import Logo from '../asset/logo.png';

const headerItemStyle = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',

  '&:hover': {
    color: '#1976d2',
  }
}

const dropdownItemStyle = {
  minWidth: 100,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '&:hover': {
    backgroundColor: '#3e3e3e',
    color: '#fff',
  }
}

const Header = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [keepAnchor, setKeepAnchor] = useState(false);
  const [elements, setElements] = useState([]);

  const onHandleHeader = (event: React.MouseEvent<HTMLElement>, item: any) => {
    if (item.hasChildren) {
      setKeepAnchor(true);
      setElements(item.elements);
      setAnchorEl(anchorEl ? null : event.currentTarget);
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    if (!keepAnchor) setAnchorEl(null);
  }, [keepAnchor])

  return (
    <Box height={100} pt={1.5} pb={1} boxSizing='border-box'>
      <Box maxWidth={1200} height={80} mx='auto' display='flex' alignItems='center' justifyContent='space-between'>
        <img src={Logo} alt='' style={{ width: 80, cursor: 'pointer' }} onClick={() => window.location.href = 'https://klik.cool' } />
        <Box width={600} display='flex' alignItems='center' justifyContent='space-evenly'>
          {Headers.map((item: any, index: number) => (
            <Box
              key={index}
              sx={headerItemStyle}
              onClick={() => { if (!item.hasChildren) navigate(item.link) }}
              onMouseEnter={event => onHandleHeader(event, item)}
              onMouseLeave={() => setKeepAnchor(false)}
            >
              <Typography>{item.text}</Typography>
              {item.hasChildren && <ExpandMoreIcon />}
            </Box>
          ))}
          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Box bgcolor='#fff' borderRadius={2} boxShadow={3} onMouseEnter={() => setKeepAnchor(true)} onMouseLeave={() => setKeepAnchor(false)}>
              {elements.map((item: any, index) => (
                <Box key={index} sx={dropdownItemStyle} onClick={() => navigate(item.link)}>
                  <Typography>{item.text}</Typography>
                </Box>
              ))}
            </Box>
          </Popper>
        </Box>
        <Box width={200} display='flex' alignItems='center' justifyContent='space-between'>
          {/*<SearchIcon sx={{ cursor: 'pointer' }} />*/}
          {/*<ColorLensIcon sx={{ cursor: 'pointer' }} />*/}
          {/*<Box display='flex' pb={0.5} borderBottom='1px solid #1976d2' sx={{ cursor: 'pointer' }}>*/}
          {/*  <EmailIcon />*/}
          {/*  <Typography ml={0.8}>SUBSCRIBE</Typography>*/}
          {/*</Box>*/}
        </Box>
      </Box>
    </Box>
  )
}

export default Header;
