import React, { useEffect, useState } from 'react';
import { Badge, Box, Button, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { GetBlogs, GetCollections } from '../common/api';
import Logo from '../asset/logo.png';

const Home = () => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await GetBlogs();
      response && setBlogs(response);
    }
    const fetchCollections = async () => {
      const response = await GetCollections();
      response && setCollections(response);
      console.log(response);
    }

    fetchBlogs();
    fetchCollections();
  }, [])

  return (
    <Box maxWidth={1300} py={2.5} mx='auto' boxSizing='border-box'>
      <Grid container>
        <Grid item xs={12} lg={9} p={1}>
          {blogs.length > 0 && <Box borderRadius={2} overflow='hidden' position='relative'>
            <img src={blogs[0].image} alt='' style={{ width: '100%' }} onClick={() => navigate(`/post/${blogs[0]._id}`)} />
            <Box position='absolute' left={40} bottom={40} right={40} zIndex={9} display='flex' flexDirection='column'>
              <Button variant='contained' size='small' sx={{ width: 'fit-content', textTransform: 'capitalize' }}>{blogs[0].category}</Button>
              <Typography variant='h4' my={1.5} fontWeight={600} color='#fff'>{blogs[0].title}</Typography>
              <Box display='flex' alignItems='center'>
                <img src={Logo} alt='' style={{ width: 36, borderRadius: 18 }} />
                <Typography ml={1} color='#fff'>{format(new Date(blogs[0].createdAt), 'MMM dd, yyyy')}</Typography>
              </Box>
            </Box>
          </Box>}
          <Grid container spacing={2} mt={2.5}>
            {blogs.slice(1, 9).map((item, index) => (
              <Grid key={index} item xs={12} lg={6} onClick={() => navigate(`/post/${item._id}`)}>
                <Box bgcolor='#fff' borderRadius={2} boxShadow={3} p={2.5}>
                  <img src={item.image} alt='' style={{ width: '100%' }} />
                  <Button>{item.type}</Button>
                  <Typography variant='h5' my={1.25}>{item.title}</Typography>
                  <Box display='flex' alignItems='center'>
                    <img src={Logo} alt='' style={{ width: 36, borderRadius: 18 }} />
                    <Typography ml={1}>{format(new Date(item.createdAt), 'MMM dd, yyyy')}</Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={3} p={1}>
          <Box bgcolor='#fff' borderRadius={2} boxShadow={3} p={2.5}>
            <Typography variant='h5' fontWeight={600}>Latest Post</Typography>
            {blogs.slice(0, 5).map((item, index) => (
              <Box mt={2.5} key={index} display='flex' alignItems='center' onClick={() => navigate(`/post/${item._id}`)}>
                <img src={item.image} alt='' style={{ width: '40%' }} />
                <Box ml={1.5}>
                  <Typography fontSize={14}>{item.title}</Typography>
                  <Typography fontSize={12}>{format(new Date(item.createdAt), 'MMM dd, yyyy')}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box bgcolor='#fff' borderRadius={2} boxShadow={3} mt={2.5} p={2.5}>
            <Typography variant='h5' fontWeight={600}>Collections</Typography>
            <List>
              {collections.slice(0, 8).map((item, index) => (
                <Box key={index}>
                  <ListItem>
                    <ListItemText>{item.title}</ListItemText>
                    <Badge badgeContent={item.volume} color='primary' />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home;
