import axios from 'axios';
import { handleLog } from './function';
import { ServerUrl } from './const';

export const GetBlogs = async () => {
  try {
    const { data } = await axios.get(`${ServerUrl}/blog/get`);
    return data.data;
  } catch (e) {
    handleLog('GetBlogs', e);
    return null;
  }
}

export const GetBlog = async (params: any) => {
  try {
    const { data } = await axios.get(`${ServerUrl}/blog/get-one`, { params });
    return data.data;
  } catch (e) {
    handleLog('GetBlogs', e);
    return null;
  }
}

export const GetCollections = async () => {
  try {
    const { data } = await axios.get(`${ServerUrl}/blog/collections`);
    return data.data;
  } catch (e) {
    handleLog('GetCollections', e);
    return null;
  }
}
