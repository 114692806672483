import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { GetBlog, GetBlogs, GetCollections } from '../common/api';
import Logo from '../asset/logo.png';
import RichTextEditor from 'react-rte';
// import parse from 'html-react-parser';

const Post = () => {

  const navigate = useNavigate();
  const { id } = useParams();

  const [blog, setBlog] = useState<any>(null);
  const [blogs, setBlogs] = useState<any[]>([]);
  const [collections, setCollections] = useState<any[]>([]);

  useEffect(() => {
    const fetchBlog = async () => {
      const response = await GetBlog({ _id: id });
      response && setBlog(response);
    }
    const fetchCollections = async () => {
      const response = await GetCollections();
      response && setCollections(response);
    }

    if (id) fetchBlog();
    fetchCollections();
  }, [id])

  useEffect(() => {
    const fetchBlogs = async () => {
      const response = await GetBlogs();
      response && setBlogs(response);
    }

    fetchBlogs();
  }, [])

  return (
    <Box maxWidth={1300} py={2.5} mx='auto' boxSizing='border-box'>
      <Breadcrumbs sx={{ my: 2.5 }}>
        <Link underline='hover' color='inherit' href='/'>Home</Link>
        <Link underline='hover' color='inherit'>Post</Link>
        <Typography color='text.primary'>{blog?._id}</Typography>
      </Breadcrumbs>
      <Grid container>
        <Grid item xs={12} lg={9} p={1}>
          {blog && <>
            
            <Button variant='contained' size='small' sx={{ textTransform: 'capitalize' }}>{blog?.category}</Button>
            
            <Typography variant='h4' mt={1.5} mb={0.5} fontWeight={600}>{blog?.title}</Typography>
            
            <Box display='flex' alignItems='center'>
              <img src={Logo} alt='' style={{ width: 36, borderRadius: 18 }} />
              <Typography ml={1}>{blog?.createdAt && format(new Date(blog?.createdAt), 'MMM dd, yyyy')} </Typography>
            </Box>
            
            <Box borderRadius={4} my={2.5} overflow='hidden'>
              <img src={blog?.image} alt='' style={{ width: '100%' }} />
            </Box>
            
            {/* {parse(blog?.description)} */}
            {/* <Typography variant='body1' mb={2.5}>{blog?.description}</Typography> */}
            <RichTextEditor
              value={RichTextEditor.createValueFromString(blog?.description, 'html')}
              readOnly
            />
          </>}
        </Grid>
        <Grid item xs={12} lg={3} p={1}>
          <Box bgcolor='#fff' borderRadius={2} boxShadow={3} p={2.5}>
            <Typography variant='h5' fontWeight={600}>Latest Post</Typography>
            {blogs.slice(0, 5).map((item, index) => (
              <Box key={index} mt={2.5} display='flex' alignItems='center' onClick={() => navigate(`/post/${item._id}`)}>
                <img src={item.image} alt='' style={{ width: '40%' }} />
                <Box ml={1.5}>
                  <Typography>{item.title}</Typography>
                  <Typography fontSize={14}>{format(new Date(item.createdAt), 'MMM dd, yyyy')}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box bgcolor='#fff' borderRadius={2} boxShadow={3} mt={2.5} p={2.5}>
            <Typography variant='h5' fontWeight={600}>Collections</Typography>
            <List>
              {collections.slice(0, 8).map((item, index) => (
                <Box key={index}>
                  <ListItem>
                    <ListItemText>{item.title}</ListItemText>
                    <Badge badgeContent={item.volume} color='primary' />
                  </ListItem>
                  <Divider />
                </Box>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Post;
