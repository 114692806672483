export const ServerUrl = 'https://api.metasalt.io/prod/admin';

export const Headers = [
  {
    text: 'Home',
    hasChildren: false,
    link: '/',
  },
  { text: 'Contact', hasChildren: false, link: '/contact' },
];

export const Footers = [
    { label: 'Terms of Use', link: 'https://klik.cool/termsOfService' },
    { label: 'Privacy Policy', link: 'https://klik.cool/privacy' },
];
